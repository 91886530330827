export default {
    pricing: [
        {
            userName: 'Anushka Mishra',
            title: 'Great resources and knowledgeable faculty',
            description: `The yocket faculty have been extremely helpful and insightful for GRE prep. In-depth knowledge of topics in both quant and verbal shown by my instructors as well as provision of adequate resources for practice makes yocket prep completely worth it.\n\nMeticulous study and helpful strategies were discussed in verbal which were required to tackle GRE's tricky verbal section. The yocket dashboard also boasts of daily vocab practice, mock tests, and subject-wise tests, all of which contain great questions with detailed solutions. Quant was also covered in a very methodical and detailed manner, ample questions were solved in class from reputable resources. This helped me gain a score of 329 (159V 170Q 5AWA) in just over a month.`,
            dateOfExperience: 'July 27, 2024',
            rating: 5,
            createdAt: 'Aug 6, 2024'
        },
        {
            userName: 'Shambhavi Singh',
            title: 'Best complete preparation course',
            description: `I am glad that I took the GRE prep course from Yocket trainers and scored 314.\n\nQuants: The concepts, examples, and practice tests given by Harish Sir cover everything one needs to know to clear the Quants section with flying colors. He is a highly proficient trainer.\n\nVerbal: This section needs immense practice for those with average English vocab. However, the English faculty gave simple tricks and tips to solve almost any type of verbal question.\n\nThere is absolutely no need to look for GRE material outside as the topics are covered in detail. These classes followed by multiple mock tests are sufficient.`,
            dateOfExperience: 'August 01, 2023',
            rating: 5,
            createdAt: 'Mar 14, 2024'
        },
        {
            userName: 'Sristi Chakraborty',
            title: 'GRE prep+ by Yocket',
            description: `Yocket offers great GRE preparation courses which helped me understand the concepts required to get a good score in GRE very clearly. The course along with its mock tests and videos can be of immense help to students who are planning on giving the test. Furthermore, Yocket also provides a very student-friendly approach to any query regarding study abroad.`,
            dateOfExperience: 'February 22, 2024',
            rating: 5,
            createdAt: 'Feb 24, 2024'
        },
        {
            userName: 'Rutuja Sankhe',
            title: 'Best for GRE prep',
            description: `Yocket Prep is a game-changer in GRE preparation. Its affordability, flexibility, and user-friendly interface set it apart. Comprehensive study materials, including refresher sessions and practice questions, ensure thorough understanding.`,
            dateOfExperience: 'March 16, 2024',
            rating: 5,
            createdAt: 'Mar 23, 2024'
        }
    ],
    onDemand: [
        {
            userName: 'Sristi Chakraborty',
            title: 'GRE prep+ by Yocket',
            description: `Yocket offers great GRE preparation courses which helped me understand the concepts required to get a good score in GRE very clearly. The course along with its mock tests and videos can be of immense help to students who are planning on giving the test. Furthermore, Yocket also provides a very student-friendly approach to any query regarding study abroad.`,
            dateOfExperience: 'February 22, 2024',
            rating: 5,
            createdAt: 'Feb 24, 2024'
        },
        {
            userName: 'Priyanshu Chatterjee',
            title: 'The best place to start your grad…',
            description: `The best place to start your grad preparations and have counseling. I was a yocket prep student. The video lectures, questions, and mock tests which are provided on their dashboard are enough and cover everything that one needs for their GRE preparation!`,
            dateOfExperience: 'March 18, 2024',
            rating: 5,
            createdAt: 'Mar 18, 2024'
        },
        {
            userName: 'Rutuja Sankhe',
            title: 'Best for GRE prep',
            description: `Yocket Prep is a game-changer in GRE preparation. Its affordability, flexibility, and user-friendly interface set it apart. Comprehensive study materials, including refresher sessions and practice questions, ensure thorough understanding.`,
            dateOfExperience: 'March 16, 2024',
            rating: 5,
            createdAt: 'Mar 23, 2024'
        },
        {
            userName: 'Jatin Ranjan',
            title: 'The Awesome Faculty',
            description: `Without a doubt, the FACULTY, they were very supportive till the very end and even after that. All doubts were instantly resolved and the Dashboard is also very useful for preparing.`,
            dateOfExperience: 'July 12, 2024',
            rating: 5,
            createdAt: 'July 12, 2024'
        }
    ],
    liveClass: [
        {
            userName: 'Anushka Mishra',
            title: 'Great resources and knowledgeable faculty',
            description: `The yocket faculty have been extremely helpful and insightful for GRE prep. In-depth knowledge of topics in both quant and verbal shown by my instructors as well as provision of adequate resources for practice makes yocket prep completely worth it.\n\nMeticulous study and helpful strategies were discussed in verbal which were required to tackle GRE's tricky verbal section. The yocket dashboard also boasts of daily vocab practice, mock tests, and subject-wise tests, all of which contain great questions with detailed solutions. Quant was also covered in a very methodical and detailed manner, ample questions were solved in class from reputable resources. This helped me gain a score of 329 (159V 170Q 5AWA) in just over a month.`,
            dateOfExperience: 'July 27, 2024',
            rating: 5,
            createdAt: 'Aug 6, 2024'
        },
        {
            userName: 'Shambhavi Singh',
            title: 'Best complete preparation course',
            description: `I am glad that I took the GRE prep course from Yocket trainers and scored 314.\n\nQuants: The concepts, examples, and practice tests given by Harish Sir cover everything one needs to know to clear the Quants section with flying colors. He is a highly proficient trainer.\n\nVerbal: This section needs immense practice for those with average English vocab. However, the English faculty gave simple tricks and tips to solve almost any type of verbal question.\n\nThere is absolutely no need to look for GRE material outside as the topics are covered in detail. These classes followed by multiple mock tests are sufficient.`,
            dateOfExperience: 'August 01, 2023',
            rating: 5,
            createdAt: 'Mar 14, 2024'
        },
        {
            userName: 'Avani Jain',
            title: '10/10',
            description: `My experience with Yocket professors during my GRE prep was eye-opening. They had a talent for breaking down tough concepts, making learning both fun and effective. I'm really thankful for all the invaluable knowledge I gained from their teaching.`,
            dateOfExperience: 'February 07, 2024',
            rating: 5,
            createdAt: 'May 10, 2024'
        },
        {
            userName: 'Sugadev K',
            title: 'Best value for money',
            description: `I've tried many GRE online classes with my friends' subscriptions and through various other sources. All had one thing missing. Either the cost, or the flexibility, or the study materials, or the practicing interface. Later I came across Yocket prep. It was one of the best I've experienced. The user interface is awesome. It was the most affordable one. Plus the vocabulary building on their site is top-notch. Overall it's the best platform for GRE preparation.`,
            dateOfExperience: 'March 19, 2024',
            rating: 5,
            createdAt: 'March 19, 2024'
        }
    ]
};
