//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const CDN = process.env.CDN_ENDPOINT;
export default {
    name: 'TestimonialCard',
    props: {
        testimonial: {
            type: Object,
            default: () => ({
                userName: '',
                userAvatar: '',
                title: '',
                description: '',
                dateOfExperience: '',
                rating: 0,
                createdAt: ''
            }),
            required: true
        }
    },
    data() {
        return {
            isExpanded: false,
            maxLength: 200
        };
    },
    computed: {
        shortenedDescription() {
            if (this.testimonial.description.length > this.maxLength) {
                return this.testimonial.description.substring(0, this.maxLength) + '...';
            }
            return this.testimonial.description;
        },
        showReadMore() {
            return this.testimonial.description.length > this.maxLength;
        },
        userAvatar() {
            const formattedUserName = this.testimonial.userName.replace(/\s/g, '_').toLowerCase();
            return `${CDN}/testprep/static/images/testimonials/${formattedUserName}.png`;
        },
        ratingUrl() {
            return `https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${this.testimonial.rating}.svg`;
        },
        formattedDescription() {
            return this.testimonial.description.replace(/\n/g, '<br>');
        },
        formattedShortenedDescription() {
            return this.shortenedDescription.replace(/\n/g, '<br>');
        },
        description() {
            return this.isExpanded ? this.formattedDescription : this.formattedShortenedDescription;
        }
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        }
    }
};
