//
//
//
//
//
//
//

export default {
    name: 'TrustPilotLabel'
};
