//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

// Static imports
import SectionHeading from '~/components/ReusableComponents/SectionHeading.vue';
import PrepFooter from '~/components/ReusableComponents/PrepFooter.vue';
import analyticEvents from '~/services/analyticEvents';
import { TEST_TYPES, TRUSTPILOT_URL } from '~/static/constants.js';
import { ROUTES } from '~/constants/pageRoutes';
import Icon from '~/components/ReusableComponents/Icon.vue';
import testimonials from '~/static/testimonials';
import TrustPilotLabel from '~/components/ReusableComponents/TrustPilotLabel.vue';
import TestimonialCard from '~/components/ReusableComponents/TestimonialCard.vue';

// Dynamic imports
const HowItWorksWeb = () => import('~/components/Home/HowItWorksWeb.vue');
const HowItWorksPWA = () => import('~/components/Home/HowItWorksPWA.vue');
const InstructionsModal = () => import('./InstructionsModal.vue');
const ModalContainer = () => import('~/components/ReusableComponents/ModalContainer.vue');
const AuthModal = () => import('~/components/Auth/AuthModal.vue');
const GetPlusStickyCta = () => import('~/components/Premium/GetPlusStickyCta.vue');
const PlanExpiryOverlay = () => import('~/components/Premium/PlanExpiryOverlay.vue');
const TrialPeriodHomeScreenPwa = () =>
    import('~/components/OverlayPrompts/TrialPeriodHomeScreenPwa.vue');
const TrialExpiryOverlay = () => import('~/components/OverlayPrompts/TrialExpiryOverlay.vue');
const AppOfferBanner = () => import('~/components/ReusableComponents/AppOfferBanner.vue');
const CDN = `${process.env.CDN_ENDPOINT}/testprep/static`;

export default {
    name: 'Home',
    components: {
        SectionHeading,
        PrepFooter,
        HowItWorksWeb,
        HowItWorksPWA,
        InstructionsModal,
        GetPlusStickyCta,
        PlanExpiryOverlay,
        ModalContainer,
        AuthModal,
        TrialPeriodHomeScreenPwa,
        Icon,
        AppOfferBanner,
        TrialExpiryOverlay,
        TestimonialCard,
        TrustPilotLabel
    },
    data() {
        return {
            // Constants
            ROUTES,
            TEST_TYPES,
            TRUSTPILOT_URL,
            yocketPrepLogo: this.$device.isMobile
                ? `${CDN}/icons/yocketPrep_small.svg`
                : `${CDN}/icons/yocketPrep_medium.svg`,
            CDN,
            // Variables
            showPwaStickyNote: false,
            mainFeatures: [
                {
                    id: 1,
                    image: `${CDN}/images/videoLessonFeature.png`,
                    title: 'Video Lessons for Concept Clarity',
                    description:
                        'Learn at your own pace, from the comfort of your home, with topic-based video lessons!'
                },
                {
                    id: 2,
                    image: `${CDN}/images/mockTestFeature.png`,
                    title: 'Full-Length GRE Mock Tests',
                    description:
                        'Get the experience of taking GRE with 4 mocks that simulate the actual test.'
                },
                {
                    id: 3,
                    image: `${CDN}/images/plannerFeature.png`,
                    title: 'Study Planner to Stay on Schedule',
                    description:
                        'Create a planner based on your test date to stay on track with your prep!'
                },
                {
                    id: 4,
                    image: `${CDN}/images/resourcesFeature.png`,
                    title: 'GRE Resources to Guide You',
                    description: 'Quench your thirst for knowledge with Expert-curated blogs'
                },
                {
                    id: 5,
                    image: `${CDN}/images/allSubjectTestsFeature.png`,
                    title: 'Section-Based Topic Tests',
                    description: 'Focus on topics that need your attention with curated topic tests'
                },
                {
                    id: 6,
                    image: `${CDN}/images/adaptiveAlgorithmFeature.png`,
                    title: 'Algorithm that Personalizes GRE Prep',
                    description:
                        'Our adaptive algorithm learns your capabilities and suggests curated practice to help you improve'
                }
            ],
            secondaryFeatures: [
                {
                    id: 1,
                    icon: `${CDN}/icons/HomeScreen/clipBoardRed.svg`,
                    title: 'Test Score Report ',
                    description:
                        'Get a detailed report of your GRE tests with insights on subject-wise performance.',
                    backgroundColor: '#FBEAED'
                },
                {
                    id: 2,
                    icon: `${CDN}/icons/HomeScreen/stopCircleYellow.svg`,
                    title: 'Pause and Save',
                    description:
                        'Life happens! You can pause and save your test progress and pick it back up later!',
                    backgroundColor: '#FDF2E0'
                },
                {
                    id: 4,
                    icon: `${CDN}/icons/HomeScreen/timerGreen.svg`,
                    title: 'Timed and Untimed Tests',
                    description:
                        'You can opt between a timed test & untimed test to suit your needs.',
                    backgroundColor: '#F0FDF4'
                },
                {
                    id: 6,
                    icon: `${CDN}/icons/HomeScreen/clipBoardTickPurple.svg`,
                    title: 'Instant Solutions',
                    description:
                        'No need to wait! Get immediate solutions with detailed explanations across all tests.',
                    backgroundColor: '#F5F3FF'
                }
            ],
            howItWorksSteps: [
                {
                    id: 1,
                    heading: 'Take the Performance Analysis Test',
                    body: 'A 30-min test to mark your competence for better GRE® preparation.',
                    image: `${CDN}/images/questions.svg`
                },
                {
                    id: 2,
                    heading: 'Get your Performance Analysis Test Report',
                    body: 'A detailed analysis of your performance to personalise the GRE® practice tests for you.',
                    image: `${CDN}/images/testReport.svg`
                },
                {
                    id: 3,
                    heading: 'Your Quick Prep Dashboard is ready',
                    body: 'Explore the precursor with our ultimate 20+ personalised topics and free GRE® mock tests.',
                    image: `${CDN}/images/dashboard.svg`
                }
            ],
            isLoginModalOpen: false,
            signUpType: analyticEvents.signup_type.home,
            testimonials: testimonials.pricing
        };
    },
    computed: {
        ...mapGetters({
            hasGivenQuickDiagnostic: 'auth/hasGivenQuickDiagnostic',
            hasGivenFullDiagnostic: 'auth/hasGivenFullDiagnostic',
            recentQuickDiagId: 'auth/recentQuickDiagId',
            recentFullDiagId: 'auth/recentFullDiagId',
            showInstructions: 'testSets/showInstructions',
            isAuthenticated: 'auth/isAuthenticated',
            user: 'auth/getUser',
            isPremiumUser: 'auth/isPremiumUser',
            quickDiagnosticData: 'testSets/quickDiagnosticData',
            showPlanExpiryOverlay: 'auth/showPlanExpiryOverlay',

            freeTrialStatus: 'auth/freeTrialStatus'
        }),
        showPwaBanner() {
            // if user is logged out or has taken FD => never show
            if (
                !this.$device.isMobileOrTablet ||
                !this.isAuthenticated ||
                this.hasGivenFullDiagnostic
            )
                return false;

            // ------- User will always be logged in and in QD stage here onwards ---------

            // if Default Dashboard is set => always show
            if (!this.hasGivenQuickDiagnostic) return true;

            // if trial expires => always show
            if (
                !this.isPremiumUser &&
                this.freeTrialStatus.activated &&
                this.freeTrialStatus.expired
            )
                return true;

            /**
             * Cases to reach here -
             * 1. user has taken QD themselves + trial is valid.
             * 2. user has taken QD themselves + premium user.
             */
            return false;
        }
    },
    mounted() {
        // If QD data not already present in store then fetch
        if (!this.quickDiagnosticData)
            this.$store.dispatch('testSets/fetchDiagnosticTestData', 'QD');
    },
    methods: {
        async startTest(source) {
            if (!this.quickDiagnosticData) {
                // If the QD data is not available, try to fetch again and contiue, if it still fails then return
                const response = await this.$store.dispatch(
                    'testSets/fetchDiagnosticTestData',
                    'QD'
                );

                if (!response.state) return;
            }

            // Set the instructions for the test to begin that is QD
            this.$store.commit('testSets/setActiveTest', this.quickDiagnosticData);
            this.$store.commit('testSets/setShowInstructions', true);

            const trackingEvent = {
                event: analyticEvents.quickdiag_start_test,
                data: {
                    source
                }
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        },
        goToDashboard() {
            if (!this.isAuthenticated) {
                if (this.$device.isMobileOrTablet) {
                    this.$store.commit('auth/setContinueRoute', this.$route.path);
                    this.$router.push({
                        path: ROUTES.LOGIN,
                        query: { tab: 'signup' }
                    });
                } else this.isLoginModalOpen = !this.isLoginModalOpen;

                const trackingEvent = {
                    event: analyticEvents.startnow_signup_begin
                };
                this.$store.dispatch('analytics/trackEvent', trackingEvent);

                return;
            }

            // Mobile device + logged in + QD or FD taken => view report
            if (
                this.$device.isMobile &&
                (this.hasGivenQuickDiagnostic || this.hasGivenFullDiagnostic)
            ) {
                // initialize with QD but if the user has already taken the FD then overwrite that
                let testTakerId = this.recentQuickDiagId;
                if (this.hasGivenFullDiagnostic) testTakerId = this.recentFullDiagId;

                this.$store.commit('questions/setCanChangeRoute', true);
                this.$router.push({
                    path: ROUTES.PERFORMANCE,
                    query: { ttid: testTakerId }
                });
                return;
            }

            this.$router.push(ROUTES.DASHBOARD);
        },
        registerObserver() {
            const reference = this.$refs.pwaMessage;

            const observer = new IntersectionObserver(
                (entries) => {
                    if (!this.scrolling) {
                        const elem = entries[0];

                        this.showPwaStickyNote =
                            elem.boundingClientRect.y < 0 && !elem.isIntersecting;
                    }
                },
                { threshold: [0.0], rootMargin: '-64px 0px 0px 0px' }
            );
            observer.observe(reference.$el || reference);
        },
        featureSectionCtaHandler() {
            this.$router.push(ROUTES.PRICING);
        },
        redirectToTrustpilotPage() {
            window.open(TRUSTPILOT_URL, '_blank');

            const trackingEvent = {
                event: analyticEvents.prep_morereviews_lp
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        }
    }
};
