//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SOCIALS, OTHER_LINKS } from '~/static/constants.js';

const CDN = `${process.env.CDN_ENDPOINT}/testprep/static`;

export default {
    name: 'HomeFooter',
    data() {
        return {
            CDN,
            SOCIALS,
            OTHER_LINKS,
            hoveredIcon: null,
            lists: [
                {
                    heading: 'OTHER LINKS',
                    items: [
                        {
                            title: 'Careers',
                            link: OTHER_LINKS.CAREERS
                        },
                        {
                            title: 'Help Center',
                            link: OTHER_LINKS.HELP_CENTER
                        },
                        {
                            title: 'Privacy Policy',
                            link: OTHER_LINKS.PRIVACY_POLICY
                        }
                    ]
                },
                {
                    heading: 'OUR PRODUCTS',
                    items: [
                        {
                            title: 'Yocket',
                            link: OTHER_LINKS.YOCKET
                        }
                    ]
                }
            ]
        };
    },
    computed: {
        socialHandles() {
            const arr = [];

            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.SOCIALS)) {
                arr.push(value);
            }
            return arr;
        }
    }
};
